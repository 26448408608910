<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('turkmer_page_title')"
                        :isFilter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <header-mobile :title="$t('turkmer_page_title')"
                               :isFilter="false">
                </header-mobile>
            </template>
            <b-tabs content-class="pt-5 position-relative" class="line-tabs" v-model="tabIndex">
                <ValidationObserver ref="personal_infoForm">
                    <b-tab :title="$t('personal_info')">
                        <b-row class="border">
                            <b-col md="8" sm="6" xs="12" lg="12">
                                <b-row>
                                    <b-col md="4" sm="6" lg="4" class="d-flex flex-column p-3">
                                        <b-col>
                                            <ValidationProvider name="foreign_document_number"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('foreign_document_number')">
                                                    <b-form-input type="text" size="sm"
                                                                  v-model="formData.foreign_document_number"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="national_id"
                                                                :rules="formData.national_id || formData.foreign_document_number?'':'required'"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('passport_number')">
                                                    <b-form-input type="text" size="sm" v-model="formData.passport_number"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="tc_no"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('tc_no')">
                                                    <b-form-input type="text" size="sm" v-model="formData.national_id"
                                                                  v-check-min-max-value></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('name')">
                                                    <b-form-input
                                                        v-model="formData.name"
                                                        v-mask="upperAlphaMask"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="surname" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('surname')">
                                                    <b-form-input
                                                        v-model="formData.surname"
                                                        v-mask="upperAlphaMask"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="city_id" rules="required"
                                                                v-slot="{ valid, errors }">
                                                <b-form-group :label="$t('leave_of_city')">
                                                    <city-selectbox v-model="formData.city_id"></city-selectbox>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-col>
                                    <b-col md="4" sm="6" lg="4" class="d-flex flex-column p-3">
                                        <b-col>
                                            <ValidationProvider name="national_id" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('gender')">
                                                    <gender-selectbox v-model="formData.gender"
                                                                      :validate-error="errors[0]">
                                                    </gender-selectbox>
                                                </b-form-group>
                                            </ValidationProvider>

                                            <ValidationProvider name="birthdate" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('date_of_birth')" class="position-relative">
                                                    <v-date-picker
                                                        v-model="formData.birthdate"
                                                        :locale="$i18n.locale"
                                                        is-expanded
                                                        :popover="{ 'visibility': 'click' }">

                                                        <template
                                                            v-slot="{ inputValue, inputEvents }">
                                                            <b-input-group>
                                                                <b-form-input
                                                                    :readonly="true"
                                                                    :value="inputValue"
                                                                    v-on="inputEvents"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button
                                                                        variant="outline-secondary"
                                                                        class="btn-40"
                                                                        disabled
                                                                    >
                                                                        <i class="ri-calendar-line"></i
                                                                        ></b-button>
                                                                </b-input-group-append>
                                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                                         v-html="errors[0]"></b-form-invalid-feedback>

                                                            </b-input-group>
                                                        </template>
                                                    </v-date-picker>
                                                </b-form-group>
                                            </ValidationProvider>


                                            <ValidationProvider name="nationality" rules="required"
                                                                v-slot="{valid, errors}">

                                                <b-form-group :label="$t('nationality')">
                                                    <country-selectbox valueType="code"
                                                                       v-model="formData.nationality_code"
                                                                       :placeholder="$t('nationality')"
                                                                       :state="errors[0] ? false:null"
                                                                       :validate-error="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>

                                            <ValidationProvider name="mobile_tel"
                                                                maxlength="11"
                                                                rules="required"
                                                                v-slot="{ valid, errors }">
                                                <b-form-group :label="$t('gsm_no')">
                                                    <b-form-input v-model="formData.mobile_tel"
                                                                  :placeholder="$t('gsm_no')"
                                                                  v-mask="'5##-###-####'"
                                                                  :state="errors[0] ? false : null"/>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>

                                            <ValidationProvider name="district_id" rules="" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('leave_of_district')">
                                                    <district-outline-selectbox
                                                        :city_id="formData.city_id"
                                                        :validate-error="errors[0]"
                                                        v-model="formData.district_id">
                                                    </district-outline-selectbox>
                                                </b-form-group>
                                            </ValidationProvider>

                                        </b-col>
                                    </b-col>
                                    <b-col md="4" sm="6" lg="4" class="d-flex flex-column p-3">
                                        <b-col>
                                            <ValidationProvider name="email" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('email')">
                                                    <b-form-input type="text"
                                                                  v-model="formData.email"
                                                                  :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="emergency_person" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('emergency_person')">
                                                    <b-form-input type="text"
                                                                  v-model="formData.emergency_person"
                                                                  :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="emergency_person_number"
                                                                maxlength="11"
                                                                rules="required"
                                                                v-slot="{ valid, errors }">
                                                <b-form-group :label="$t('emergency_person_phone')">
                                                    <b-form-input v-model="formData.emergency_person_number"
                                                                  :placeholder="$t('gsm_no')"
                                                                  v-mask="'5##-###-####'"
                                                                  :state="errors[0] ? false : null"/>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>

                                            <ValidationProvider
                                                name="address"
                                                rules="required"
                                                v-slot="{ valid, errors }"
                                            >
                                                <b-form-group :label="$t('address')">
                                                    <b-form-textarea
                                                        v-model="formData.address"
                                                    ></b-form-textarea>
                                                </b-form-group>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                    class="mb-2"
                                                ></b-form-invalid-feedback>
                                            </ValidationProvider>

                                        </b-col>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-tab>
                </ValidationObserver>
                <ValidationObserver ref="program_selectForm">
                    <b-tab :title="$t('program_select')">
                        <b-row class="border">
                            <b-col md="8" sm="6" xs="12" lg="12">
                                <b-row>
                                    <b-col md="6" sm="6" lg="6" class="d-flex flex-column p-3">
                                        <b-col>
                                            <ValidationProvider name="program_type" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('program')">
                                                    <parameter-selectbox
                                                        code="turkmer_program_types"
                                                        v-model="formData.program_type"
                                                        :validate-error="errors[0]"
                                                    />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('course_exam_type')">
                                                    <multiselect placeholder="seçiniz"
                                                                 v-model="formData.program_code"
                                                                 :options="courseCodeOptions" :select-label="''"
                                                                 label="text"
                                                                 track-by="value"
                                                                 :selected-label="''" :deselect-label="''"
                                                                 :searchable="false"
                                                                 :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                    >
                                                    </multiselect>
                                                </b-form-group>
                                                <div class="invalid-feedback d-block" v-if="errors[0]"
                                                     v-html="errors[0]"></div>

                                            </ValidationProvider>
                                        </b-col>
                                    </b-col>
                                    <b-col md="6" sm="6" lg="6" class="d-flex flex-column p-3">
                                        {{formData.before_turkish_certificate_status}}
                                            <b-col>
                                                <b-form-group :label="$t('language_text')" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio-group
                                                        v-model="formData.before_turkish_certificate_status"
                                                        :options="options"
                                                        :aria-describedby="ariaDescribedby"
                                                    ></b-form-radio-group>
                                                </b-form-group>
                                            </b-col>

                                        <b-col v-if="formData.before_turkish_certificate_status===1" class="mt-3">
                                            <ValidationProvider name="file" :rules="formData.before_turkish_certificate_status===1?'required':''" v-slot="{errors}">
                                                <b-form-group :label="$t('certificate_upload')">
                                                    <div class="d-flex custom-file-upload">
                                                        <b-form-file
                                                            v-model="formData.before_turkish_certificate"
                                                            :state="errors[0] ? false : null"
                                                            :placeholder="$t('select_file')"
                                                            :drop-placeholder="$t('drop_file')"
                                                            ref="fileInput"
                                                            ></b-form-file>
                                                        <b-button variant="outline-secondary"
                                                                  @click="$refs.fileInput.$el.childNodes[0].click();">
                                                            {{ $t('browse') }}
                                                        </b-button>
                                                    </div>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]"
                                                         v-html="errors[0]"></div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-tab>
                </ValidationObserver>
                <ValidationObserver ref="documentsForm">
                    <b-tab :title="$t('documents')">
                        <b-row class="border">
                            <b-col md="8" sm="6" xs="12" lg="12">
                                <b-row>
                                    <b-col md="6" sm="6" lg="6" class="d-flex flex-column p-3">
                                        <b-col>
                                            <ValidationProvider name="image" rules="required" v-slot="{errors}">
                                                <b-form-group :label="$t('please_upload_image')">
                                                    <div class="d-flex custom-file-upload">
                                                        <b-form-file
                                                            v-model="formData.image"
                                                            :state="errors[0] ? false : null"
                                                            :placeholder="$t('select_file')"
                                                            :drop-placeholder="$t('drop_file')"
                                                            ref="fileInput"
                                                            ></b-form-file>
                                                        <b-button variant="outline-secondary"
                                                                  @click="$refs.fileInput.$el.childNodes[0].click();">
                                                            {{ $t('browse') }}
                                                        </b-button>
                                                    </div>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]"
                                                         v-html="errors[0]"></div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <div class="mb-2 mt-3">
                                                <b-button variant="primary" @click="sendForm">
                                                    {{ $t('send').toUpper() }}
                                                </b-button>
                                            </div>
                                        </b-col>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-tab>
                </ValidationObserver>
            </b-tabs>
            <b-row>
                <b-col style="display: flex;justify-content: center;align-items: center;margin-top: 15px">
                    <b-button variant="outline-info" class="mr-2" :disabled="buttonDisabled" v-b-tooltip.hover
                              :title="$t('back')" @click="goToBack"><i class="ri-arrow-left-line"></i></b-button>
                    <b-button variant="outline-info" :disabled="buttonDisabled" v-b-tooltip.hover :title="$t('next')"
                              @click="goTo"><i class="ri-arrow-right-line"></i></b-button>
                </b-col>
            </b-row>
        </app-layout>
    </div>
</template>
<script>
import MultiParameterSelectbox from "@/components/interactive-fields/MultiParameterSelectbox.vue";
import CitySelectbox from "@/components/interactive-fields/CitySelectbox.vue";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import MultiText from "@/components/interactive-fields/MultiText.vue";
import TurkmerApplicationProgramSelectBox from "@/components/interactive-fields/TurkmerApplicationProgramSelectBox.vue";
import DistrictOutlineSelectbox from "../../../components/interactive-fields/DistrictOutlineSelectbox.vue";
import programService from "../../../services/ProgramService";
import qs from "qs";
import moment from "moment/moment";
import TurkmerApplicationService from "@/services/TurkmerApplicationService";

export default {
    components: {
        DistrictOutlineSelectbox,
        TurkmerApplicationProgramSelectBox,
        MultiText,
        MultiParameterSelectbox,
        CitySelectbox,
        LinedTextarea
    },
    data() {
        return {
            buttonDisabled: false,
            upperAlphaMask: {
                mask: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
                tokens: {
                    'A': {
                        pattern: /[A-Za-z ÇĞİÖŞÜçğıöşü]/,
                        transform: function (v) {
                            v = v.replaceAll('i', 'İ');
                            return v.toUpper();
                        }
                    }
                }
            },
            tabIndex: 0,
            formData: {
                national_id: null,
                tc_no: null
            },
            queryParams: {
                filter: {
                    faculty_code: '50'
                },
            },
            courseCodeOptions: [],
            options: [
                {text: this.$i18n.locale=='tr'?'Evet':'Yes', value: 1},
                {text: this.$i18n.locale=='tr'?'Hayır':'No', value: 0},
            ],
            programs: []
        }
    },
    watch: {
        tabIndex(newValue) {
            if (newValue > 3) {
                this.tabIndex = 3
            }
            if (newValue < 0) {
                this.tabIndex = 0
            }
        },
        'formData.program_type'(newValue) {
            this.courseCodeOptions = []
            if (newValue == 'Exam') {
                this.programs.forEach(itm => {
                    if (['50020018'].includes(itm.code)) {
                        this.courseCodeOptions.push({
                            text: itm.code + '-' + itm.name,
                            value: itm.code
                        })
                    }
                })
            } else if (newValue == 'Course') {
                this.programs.forEach(itm => {
                    if (!['50020018', '50011001'].includes(itm.code)) {
                        this.courseCodeOptions.push({
                            text: itm.code + '-' + itm.name,
                            value: itm.code
                        })
                    }
                })
            }
        }
    },
    methods: {
        goToBack() {
            this.tabIndex-=1
        },
        async goTo() {
             let personal_infoForm = await this.$refs.personal_infoForm.validate()
             if (personal_infoForm && this.tabIndex==0) {
                this.tabIndex+=1
             }
             let program_selectForm=await this.$refs.program_selectForm.validate()
             if (personal_infoForm && program_selectForm && this.tabIndex==2){
                 this.tabIndex += 1
             }
             let documentsForm=await this.$refs.documentsForm.validate()
             if(personal_infoForm && program_selectForm && documentsForm){
                 this.tabIndex += 1
             }
        },
        async sendForm() {
            let personal_infoForm = await this.$refs.personal_infoForm.validate()
            let program_selectForm=await this.$refs.program_selectForm.validate()
            let documentsForm=await this.$refs.documentsForm.validate()
            if (personal_infoForm && program_selectForm &&  documentsForm) {
                this.formData.birthdate=moment(this.formData.birthdate).format("YYYY-MM-DD");
                this.formData.program_code = this.formData.program_code.value

                const formData = new FormData();
                formData.append('image', this.formData.image[0]);
                formData.append('before_turkish_certificate', this.formData.before_turkish_certificate[0]);
                formData.append('address', this.formData.address);
                formData.append('before_turkish_certificate_status', this.formData.before_turkish_certificate_status);
                formData.append('birthdate', this.formData.birthdate);
                formData.append('city_id', this.formData.city_id);
                formData.append('district_id', this.formData.district_id);
                formData.append('email', this.formData.email);
                formData.append('emergency_person', this.formData.emergency_person);
                formData.append('emergency_person_number', this.formData.emergency_person_number);
                formData.append('gender', this.formData.gender);
                formData.append('mobile_tel', this.formData.mobile_tel);
                formData.append('name', this.formData.name);
                formData.append('national_id', this.formData.national_id);
                formData.append('nationality_code', this.formData.nationality_code);
                formData.append('program_code', this.formData.program_code);
                formData.append('program_type', this.formData.program_type);
                formData.append('reason', this.formData.reason);
                formData.append('semester_id', this.formData.semester_id);
                formData.append('status', this.formData.status);
                formData.append('surname', this.formData.surname);

                TurkmerApplicationService.store(formData)
                    .then(response=>{

                    }).catch(err=>{
                    this.showErrors(err)
                })



            }
        },
        getPrograms() {
            const config = {
                params: {
                    ...this.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };
            programService.getProgramOptions(config)
                .then(response => {
                    this.programs = response.data.data
                }).catch(err => {
                this.showError(err)
            })
        }
    },
    created() {
        this.getPrograms()
    }
}
</script>
